import API from '@axios'
import { stringify as toQuery } from 'query-string'
import { apiHelper } from '@/helpers/apiHelper'

const API_URL = '/api/crm'

export default {
  ...apiHelper(API_URL),

  getModels(query) {
    return API.get(`${API_URL}/models?${toQuery(query)}`)
  },

  getModel(id) {
    return API.get(`${API_URL}/models/${id}`)
  },

  getProduct(id) {
    return API.get(`${API_URL}/products/${id}`)
  },
  getCategories() {
    return API.get('api/metastatus/category')
  },

  addModel(payload) {
    return API.post(`${API_URL}/models`, payload)
  },

  updateModel(id, payload) {
    return API.put(`${API_URL}/models/${id}`, payload)
  },

  deleteModel(id) {
    return API.delete(`${API_URL}/models/${id}`)
  },

  addLevel(payload) {
    return API.post(`${API_URL}/model-levels`, payload)
  },

  updateLevel(id, payload) {
    return API.put(`${API_URL}/model-levels/${id}`, payload)
  },

  deleteLevel(id) {
    return API.delete(`${API_URL}/model-levels/${id}`)
  },

  getAgreementTypes() {
    return API.get('/api/advance_field/custom_schema/samarbeidsavtale/type_juridisk_dokument')
  },

  removeProduct(id) {
    return API.delete(`${API_URL}/products/${id}`)
  },

  addProduct(payload) {
    return API.post(`${API_URL}/products`, payload)
  },

  updateProduct(id, payload) {
    return API.put(`${API_URL}/products/${id}`, payload)
  },
  export() {
    return API.put(`${API_URL}/model-export/v1`, {})
  },
}
